import React, { useState, useEffect } from "react"
import { navigate, graphql, useStaticQuery, Link } from "gatsby"
import SEO from "../components/seo"
import { useSelector, useDispatch } from "react-redux"
import ProductsPanier from "../components/productsPanier"
import { valideCmdAPI } from "../actions/panierAPI"
import Layout from "../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons"

import groceriesIcon from "../images/icon/paper-bag.svg"

export const Panier = props => {

  const dataGroup = useStaticQuery(graphql`
    query dataPanier{
      allMysqlGroupe {
        edges {
          node {
            group_id
            group_address
            group_ville
            group_horaire
            group_person
            group_zone_id
          }
        }
      }
      allMysqlZone {
        nodes {
          zone_info
          zone_id
        }
      }
    }
  `)

  const dispatch = useDispatch()
  const identifiants = useSelector(s => s.identifiants)
  const panier = useSelector(state => state.panierR)
  const loading = useSelector(s => s.loadingR)
  const [form, setForm] = useState("")
  const [jourRetrait, setJourRetrait] = useState((identifiants.identifiants && identifiants.identifiants.jourRetrait) || null)

  const isBrowser = typeof window !== "undefined";

  if (isBrowser && panier.products.length === 0) {
    navigate(identifiants.identifiants === null ? "/" : "/apps/dashboard/")
  }

  const zoneId = panier.products.length && panier.products[0].zone !== undefined ? panier.products[0].zone : ""
  const zoneName = panier.products.length && panier.products[0].zone !== undefined ? dataGroup.allMysqlZone.nodes.find(lazone => parseInt(lazone.zone_id) === parseInt(panier.products[0].zone)).zone_info : ""
  const listGroup = dataGroup.allMysqlGroupe.edges.filter(legroupe => parseInt(legroupe.node.group_zone_id) === parseInt(panier.zone) && parseInt(legroupe.node.group_id) === parseInt(panier.pickupPoint))

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!form && identifiants.identifiants && identifiants.identifiants.remarques) {
        setForm(identifiants.identifiants.remarques);
      }
    }
  }, [identifiants, form]);

  const handleUpdate = event => {
    setForm(event.target.value)
  }

  const valideCmd = () => {
    if (loading.loading) return;
    if (identifiants.identifiants === null) {
      navigate("/connexion?redirect=/panier")
    } else {
      const commande = {
        cmd_id: [...new Set( panier.products.map(product => product.product_livr_id)) ],
        customer_id: identifiants.identifiants.customer_id,
        remarque: form,
        jourLivraison: jourRetrait
      }
      dispatch(valideCmdAPI(commande, identifiants.identifiants.customer_id, identifiants.identifiants.customer_pass, panier.zone, panier.pickupPoint))
    }
  }

  return (
    <>
      <Layout>
        <SEO title="Mon Panier" description="Votre panier AgroBioConso" />
        <section className="hero pattern-zigzag-light" style={{ marginTop: -4 }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title dreamland is-size-2">
              Mon Panier
            </h1>
          </div>
        </div>
      </section>
      <section className="pattern-cross-dots">
        <div className="container is-fluid pt-5 pb-5">
          <div className="box pt-5 pb-5 pl-5 pr-5">
          {identifiants.identifiants && identifiants.identifiants.currentCmdEndInput && identifiants.identifiants.currentCmdEndInput.map((livraison, index) => (
            <div className="notification" style={{background: "#4d9f16", color: "#fff"}} key={livraison[0]+index}>
              Vous avez confirmé votre commande pour la livraison <strong>{livraison[0]}</strong>. Vous pouvez modifier cette commande jusqu'au <strong>{livraison[1]}</strong> à minuit.
            </div>
          ))}
            {listGroup && listGroup[0] ? <p className="subtitle">Point de retrait : {listGroup[0].node.group_person} - {listGroup[0].node.group_address} {listGroup[0].node.group_ville} - {listGroup[0].node.group_horaire} </p> : ""}

          <ProductsPanier showQuantite={true} />

          

          


            <div className="columns mt-5">
              <div className="column">
              {panier.products.length > 0 ?
                <>
                  <Link to={`/producteur/${zoneId}-${zoneName}/produits`} className="ml-4">
                    <button className="button is-success is-light is-large mb-4 dreamland is-size-3" style={{height: "auto", whiteSpace: "normal", lineHeight: "1.5rem"}}>
                      <img
                        src={groceriesIcon}
                        style={{
                          width: 40,
                          position: "relative",
                          top: 12,
                          marginRight: 15,
                        }}
                        alt="Produits"
                      />
                      Ajouter d'autres produits
                    </button>
                  </Link>
                </>
                : ""}
              </div>
              <div className="column">
                {panier.pickupPoint === 91 ? <div className="ml-5 mb-3">
                  <label htmlFor="choixJour">Choix du jour de retrait de la commande au magasin de la ferme de They :</label>
                  <div className="control">
                    <div className="select" onChange={e => setJourRetrait(e.target.value || null)}>
                      <select id="choixJour" defaultValue={jourRetrait || ""}>
                        <option value="">Choisir un jour</option>
                        <option value="3">Mercredi de 14h à 19h</option>
                        <option value="5">vendredi de 9h à 12h ou de 14h à 19h</option>
                        <option value="6">Samedi de 9h à 12h</option>
                      </select>
                    </div>
                  </div>
                </div>: null}
                <div className="control">
                  <label htmlFor="other" className="ml-5">Remarques éventuelles à destination du préparateur de votre commande :</label>
                  <textarea
                    type="textarea"
                    id="other"
                    name="other"
                    onChange={e => handleUpdate(e)}
                    className="input textarea ml-4 mt-2"
                    placeholder=" Remarques supplémentaires"
                    rows="3"
                    maxLength="500"
                    value={form}
                  />
                </div>
                <div className="has-text-right mt-5">
                  {panier.pickupPoint === 91 && !jourRetrait ? 
                    <div className="notification is-warning has-text-centered">Choisissez votre jour de retrait au magasin pour pouvoir valider votre commande.</div> : 
                    <button className={`button is-large dreamland ${loading.loading ? `is-loading` : ``}`} onClick={() => valideCmd()} style={{
                      height: "auto", padding: "0.5rem 1.5rem", fontSize: "2.5rem", background: "#4d9f16", color: "#fff", whiteSpace: "normal", lineHeight: "2.5rem"}}>
                      <FontAwesomeIcon icon={faCheckCircle} className="mr-5" /> Valider ma commande
                    </button>}
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Panier

import React from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { updateProduct, removeProduct } from "../actions/panier"
import { updProductAPI, delProductAPI} from "../actions/panierAPI"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"

export const ProductsPanier = props => {
  const dispatch = useDispatch()
  const panier = useSelector(state => state.panierR)
  const identifiants = useSelector(s => s.identifiants)

  const handleRemove = id_product => {
    if (identifiants.identifiants !== null) {
    const product = panier.products.filter(u => u._id === id_product)[0]
      dispatch(
        delProductAPI({
          ...product,
          person: identifiants.identifiants.customer_id
        }, identifiants.identifiants.customer_id, identifiants.identifiants.customer_pass)
      )
    } else {
      dispatch(removeProduct(id_product))
    }
  }

  const handleUpdate = (evt, _id) => {
    evt.preventDefault()
    const product = panier.products.filter(u => u._id === _id)[0]
    const qty = evt.target.value;

    if (qty !== '') {
      dispatch(
        updateProduct({
          ...product,
          _id,
          qty: qty,
        })
      )
      if (identifiants.identifiants !== null) {
        dispatch(
          updProductAPI({
            ...product,
            qty: qty,
            group : panier.pickupPoint,
            person: identifiants.identifiants.customer_id
          }, identifiants.identifiants.customer_id, identifiants.identifiants.customer_pass)
        )
      }
    } else {
      handleRemove(_id);
    }
  }

  return (
    <>
      <div className="columns has-text-weight-bold has-text-centered is-hidden-touch mt-4" style={{ background: "#FBFBFB" }}>

        <div style={{ borderLeft: "0" }} className="panier header column is-5">
          Produit
        </div>
        <div style={{ borderLeft: "0" }} className="panier header column is-2">
          Prix unitaire 
        </div>
        <div style={{ borderLeft: "0" }} className={`panier header column is-${props.showQuantite ? 2 : 3}`}>
          Qté
        </div>
        {props.showQuantite && <div style={{ borderLeft: "0" }} className="panier header column is-1" />}
        <div style={{ borderLeft: "0" }} className="panier header column is-2">
          Total 
        </div>
      </div>

      {panier
        ? panier.products
            .sort((a, b) => a.position - b.position)
            .map(ligne => (
              <div key={ligne._id} className="columns is-desktop panier line">
                <div className="column is-5">
                  <p
                    key={`body`}
                    className="ml-4"
                    id="___gatsby"
                    dangerouslySetInnerHTML={{
                      __html: ligne.product_name,
                    }}
                  />
                </div>
                <div className="column is-2 fullcenter">
                  <span className="is-hidden-desktop">Prix unitaire :&nbsp;</span>
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(ligne.product_price)}{' / '}{ligne.price_unit}
                </div>
                <div className={`column is-${props.showQuantite ? 2 : 3} fullcenter`}>
                  <span className="is-hidden-desktop">Quantité :&nbsp;</span>
                  {props.showQuantite ? (
                     <input
                     type="number"
                     style={{ marginLeft: "1rem",width:"70px" }}
                     id={`quantite-${ligne._id}`}
                     defaultValue={String(ligne.qty).replace('.00', '').replace(',00', '')}
                     onChange={e => { if (parseFloat(e.target.value)) { handleUpdate(e, ligne._id) } } }
                     onBlur={e => { if (parseFloat(ligne.qty) && !parseFloat(e.target.value)) { handleRemove(ligne._id) } } }
                     className="input input-number"
                     min={ligne.product_min_value}
                     max={ligne.product_max_value}
                     step={parseInt(ligne.product_entier) === 1 ? 1 : 0.1}
                     required
                   />
                  ) : (
                    ligne.qty
                  )}
                   

                  &nbsp;
                </div>
                {props.showQuantite && (
                <div className="column is-1 fullcenter">
                  <button className="button is-danger" onClick={() => {
                    const qtyElem = window.document.querySelector(`#quantite-${ligne._id}`);
                    if (qtyElem && parseFloat(qtyElem.value)) {
                      handleRemove(ligne._id);
                    }
                  }}>
                    <FontAwesomeIcon icon={faTrashAlt} className="icon-play" />
                  </button>
                </div>)}
            
                <div className="column fullcenter">
                  <span className="is-hidden-desktop">Total produit TTC :&nbsp;</span>
                    {new Intl.NumberFormat("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    }).format(ligne.product_price * ligne.qty)}
                </div>
              </div>
            ))
        : null}
      {parseInt(panier.pickupPoint) === 213 ? <div style={{textAlign: "right", margin: "1rem", fontSize: "1.2rem"}}>Frais de livraison : 5,00 €</div> : null}
      <div className="columns">
        <div className="column is-8"></div>
        <div className="column ">
        <table className="sousTotaux">
          <tbody>
            {identifiants?.identifiants?.customer_discount ?
          (<tr>
              <td style={{padding: '10px'}}>
                Remise commerciale ({ identifiants?.identifiants?.customer_discount }%)
              </td>
              <td style={{textAlign: "center", padding: '10px'}}>
                <strong>
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(- panier.products.reduce((acc, cur) => acc + Math.round((cur.product_price * cur.qty * (identifiants?.identifiants?.customer_discount / 100) + Number.EPSILON) * 100) / 100, 0) + (parseInt(panier.pickupPoint) === 213 ? 5 : 0))}{" "}
                </strong>
              </td>
            </tr>) : null }
            <tr>
              <td className="tfooter" style={{padding: '10px'}}>
                <strong>Total</strong>
              </td>
              <td className="tfooter" style={{padding: '10px'}}>
                <strong>
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(panier.products.reduce((acc, cur) => acc + Math.round((cur.product_price * cur.qty * (1 - (identifiants?.identifiants?.customer_discount || 0) / 100) + Number.EPSILON) * 100) / 100, 0) + (parseInt(panier.pickupPoint) === 213 ? 5 : 0))}{" "}
                  TTC
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </>
  )
}

ProductsPanier.propTypes = {
  showQuantite: PropTypes.bool,
}

export default ProductsPanier

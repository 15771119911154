import { clearLoading, showLoading, setProductSort } from "./index"
import { auth } from "./../actions/auth"
import {logError} from "../services/log"

export const addProductAPI = (product, userId, password) => {
  var url = process.env.GATSBY_API_URL + "/public/tofront/AddProductAPI"
  return dispatch => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product: product,
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (result) {
        if (result.status === "error") {
          dispatch(clearLoading(false, ""))
          logError('addProduct result error', {result, product})
          dispatch(auth(userId, password, false, true))
        } else {
          dispatch(clearLoading(true, ""))
        }
      })
      .catch(function (error) {
        dispatch(clearLoading(false, ""))
        console.log("Request failed", error)
        logError('addProduct catch', {error, product})
        dispatch(auth(userId, password, false, true))
      })
  }
}

export const updProductAPI = (product, userId, password) => {
  var url = process.env.GATSBY_API_URL + "/public/tofront/UpdProductAPI"
  return dispatch => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product: product,
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (result) {
        if (result.status === "error") {
          dispatch(clearLoading(false, ""))
          logError('updProduct result error', {result, product})
          dispatch(auth(userId, password, false, true))
        } else {
          dispatch(clearLoading(true, ""))
        }
      })
      .catch(function (error) {
        dispatch(clearLoading(false, ""))
        console.log("Request failed", error)
        logError('updProduct catch', {error, product})
        dispatch(auth(userId, password, false, true))
      })
  }
}

export const delProductAPI = (product, userId, password) => {
  var url = process.env.GATSBY_API_URL + "/public/tofront/DelProductAPI"
  return dispatch => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product: product,
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (result) {
        if (result.status === "error") {
          dispatch(clearLoading(false, ""))
          logError('delProduct result error', {result, product, userId, password})
        } else {
          dispatch(clearLoading(true, ""))
        }
        dispatch(auth(userId, password, false, true))
      })
      .catch(function (error) {
        dispatch(clearLoading(false, ""))
        console.log("Request failed", error)
        logError('delProduct catch', {error, product, userId, password})
        dispatch(auth(userId, password, false, true))
      })
  }
}

export const valideCmdAPI = (commande, userId, password, zone, pickupPoint) => {
  var url = process.env.GATSBY_API_URL + "/public/tofront/valideCmdAPI"
  return dispatch => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        commande,
        zone,
        pickupPoint
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (result) {
        if (result) {
          dispatch(clearLoading(true, ""))
          dispatch(auth(userId, password, "/apps/dashboard/", true))
        } else {
          dispatch(clearLoading(false, ""))
          alert('L\'envoi du message de confirmation a échoué, veuillez contacter l\'assistance technique');
          logError('valideCmd result error', {commande, userId, password, zone, pickupPoint})
          dispatch(auth(userId, password, false, true))
        }
      })
      .catch(function (error) {
        dispatch(clearLoading(false, ""))
        alert('Une erreur s\'est produite, veuillez contacter l\'assistance technique');
        console.log("Request failed", error)
        logError('valideCmd catch', {error, commande, userId, password, zone, pickupPoint})
        dispatch(auth(userId, password, false, true))
      })
  }
}

export const removeCmdAPI = (commandeId, userId, password) => {
  var url = process.env.GATSBY_API_URL + "/public/tofront/removeCmdAPI"
  return dispatch => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        commandeId
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (result) {
        if (result.status === "error") {
          dispatch(clearLoading(false, ""))
        } else if (result) {
          dispatch(clearLoading(true, ""))
          dispatch(auth(userId, password, "/apps/mon-compte/", true))
        } else {
          alert('Erreur de réponse du serveur')
          dispatch(clearLoading(false))
          logError('removeCmd result error', {commandeId, userId, password})
          dispatch(auth(userId, password, false, true))
        }
      })
      .catch(function (error) {
        dispatch(clearLoading(false, ""))
        console.log("Request failed", error)
        logError('removeCmd catch', {error, commandeId, userId, password})
        dispatch(auth(userId, password, false, true))
      })
  }
}

export const setProductSortAPI = (sort, userId) => {
  var url = process.env.GATSBY_API_URL + "/public/tofront/setProductSort"
  return dispatch => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sort, userId }),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (result) {
        if (result.status === "error") {
          alert('Erreur de réponse du serveur')
          dispatch(clearLoading(false))
          logError('setProductSort result error', {sort, userId})
        } else {
          dispatch(clearLoading(true, ""))
          if (result) {
            dispatch(setProductSort(sort));
          }
        }
      })
      .catch(function (error) {
        dispatch(clearLoading(false, ""))
        console.log("Request failed", error)
        logError('setProductSort catch', {error, sort, userId})
      })
  }
}